<template>
  <b-modal
    id="modal-partner"
    ref="refModalPartner"
    title="Đối tác"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Mã đối tác <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="code"
                  v-model="itemLocal.code"
                  placeholder="HomeDoc"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col cols="8">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên đối tác <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Nền tảng bác sĩ của gia đình HomeDoc"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>

          <!-- Phone -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  SĐT <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phoneNumber"
                  placeholder="024.37368315"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Email -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <template v-slot:label>
                  Email <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="itemLocal.email"
                  placeholder="doitac@vntelehealth.com"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row>
          <!-- Email -->
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="eventUrl"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Callback url <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="eventUrl"
                  v-model="itemLocal.eventUrl"
                  placeholder="https://demo.com/callback"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- password -->
            <b-form-group
              label="Mật khẩu mới"
            >
              <template
                v-slot:label
              >
                Mật khẩu mới
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu mới"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="itemLocal.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- confirm password -->
            <b-form-group
              label="Xác nhận mật khẩu"
            >
              <template
                v-slot:label
              >
                Xác nhận mật khẩu
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Xác nhận mật khẩu"
                vid="confirmPassword"
                rules="required|password|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="itemLocal.confirmPassword"
                    class="form-control-merge"
                    :type="passwordConfirmFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="confirmPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordConfirmToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordConfirmVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Trạng thái tài khoản"
              rules="required"
            >

              <select-partner-status
                v-model="itemLocal.statusValue"
                :default-value="itemLocal.status"
                label="Trạng thái tài khoản"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BInputGroupAppend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import SelectPartnerStatus from '@/views/components/SelectPartnerStatus.vue';
import usePartnerModal from './usePartnerModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    SelectPartnerStatus,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalPartner,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = usePartnerModal(props, emit, refFormObserver);

    return {
      refModalPartner,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
