import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import partnerStoreModule from '../partnerStoreModule';

export default function usePartnerModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'partner';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, partnerStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModalPartner = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      phoneNumber: itemLocal.value.phoneNumber,
      email: itemLocal.value.email,
      status: itemLocal.value.status,
      code: itemLocal.value.code,
      eventUrl: itemLocal.value.eventUrl,
    };

    if (itemLocal.value.password && itemLocal.value.password.length > 0) {
      data.password = itemLocal.value.password;
    }
    if (itemLocal.value.statusValue) {
      data.status = itemLocal.value.statusValue.value;
    }

    if (itemLocal.value._id) {
      data._id = itemLocal.value._id;
      store
        .dispatch('partner/updatePartner', data)
        .then(() => { })
        .then(() => {
          refModalPartner.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('partner/createPartner', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModalPartner.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModalPartner,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
